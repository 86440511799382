import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

const Footer = (props) => {
    return (
        <FooterContainer>
          <Link to="/Impressum">Impressum</Link> -  © Regina Prohn 2017  - <Link to="/Datenschutz" >Datenschutz</Link>
        </FooterContainer>
    );
}
const FooterContainer = styled.div`
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: ${props => props.theme.colors.secondary};
`;

export default Footer;
