import React from 'react';
import Gallery from '../../components/Gallery.js';
import TextBlockOben from '../../components/TextBlockOben';

const images = [
  {
    src: require('../../images/TL_Sitzkissen_170867.jpg'),
    title: '',
    caption: 'Sitzfellchen grau Größe: ca. 38 cm, je nach Wollstruktur'
  },
  {
    src: require('../../images/TL_Sitzkissen_170868.jpg'),
    title: '',
    caption: 'Sitzfellchen braun Größe: ca. 38 cm, je nach Wollstruktur'
  },
  {
    src: require('../../images/TL_Sitzkissen_170869.jpg'),
    title: '',
    caption: 'Sitzfellchen dunkelgrau Größe: ca. 38 cm, je nach Wollstruktur'
  },
];


const Sitzkissen = (props) => {
  return (
    <TextBlockOben>
            <h1>Sitzkissen</h1>
            <p>Die Sitzkissen werden aus der Rohwolle einheimischer Landschaftsschafe in einem Nassfilzverfahren hergestellt.</p>
            <p>Ich arbeite mit den natürlichen Farben der Schafrassen.</p>
            <p>Wenn ihr Wünsche habt, kontaktiert mich bitte, dann erhaltet ihr von mir Fotos von den zur Zeit verfügbaren Sitzkissen.
            <br/>Unten sehr ihr einige Beispiele.</p>
            <p>- Für mehr Infos drückt auf das entsprechende Bild. -
            </p>
        <Gallery images={images} />
    </TextBlockOben>
  );
}


export { Sitzkissen };
