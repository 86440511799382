import React from "react";
import styled, { withTheme } from "styled-components";

const GridContainer = withTheme(styled.div`
  display: grid;
  @media (max-width: 750px) {
    display: block;
    margin: 10px;
    padding: 10px;
  }
  grid-gap: 10px;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  width: 90%;
  margin: auto;
  padding-bottom: 10px;
  padding-top: 20px;
  text-align: left;
`);

const Header = withTheme(styled.div`
  background-color: ${props => props.theme.colors.secondary};
  align-items: center;
  display: grid;
  grid-template-columns: auto auto;
  & > * {
    font-weight: bold;
    padding: 8px;
  }
`);

const NewsItem = props => (
  <GridContainer>
    <Header>
      <span>{props.title}</span>
      <span style={{ textAlign: "right" }}>{props.date}</span>
    </Header>
    <div>
      {props.image && (
        <img
          src={props.image}
          style={{
            float: "left",
            maxWidth: "30%",
            marginRight: "8px",
            marginBottom: "8px"
          }}
        />
      )}
      <span>{props.children}</span>
    </div>
  </GridContainer>
);
export default NewsItem;
