import React from "react";
import { useRoutes } from "react-router";
import { HashRouter } from "react-router-dom";

import routes from "../routes/navigation";
import styled from "styled-components";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Navigation from "./Navigation/index.js";
import Flexbox from './Flexbox';

const routeComponents = routes.flatMap((route, i) => {
  if (Array.isArray(route.to)) {
    return route.to.map((subroute, j) => ({
      path: subroute.to,
      element: <subroute.component />,
    }));
  } else {
    return [
      {
        path: route.to,
        element: <route.component />,
      },
    ];
  }
});

const Router = () => {
  return useRoutes(routeComponents);
};

const AppRouter = (props) => {
  return (
    <HashRouter>
      <LayoutContainer flexDirection="column" minHeight="100vh">
        <Flexbox>
          <Header />
        </Flexbox>
        <Flexbox>
          <Navigation links={routes} maxWidth={980} />
        </Flexbox>
        <Flexbox flexGrow={1}>
          <Router />
        </Flexbox>
        <Flexbox>
          <Footer />
        </Flexbox>
      </LayoutContainer>
    </HashRouter>
  );
};

const LayoutContainer = styled(Flexbox)`
  width: 100%;
  margin: auto;
`;

export default AppRouter;
