import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const LiLink = ({to,children,responsive}) => (
  <NavLi responsive={responsive}>
    <Link to={to}>
      {children}
    </Link>
  </NavLi>
);

const NavLi = styled.li`
  user-select: none;
  float: ${props => props.responsive ? "none" : "left"};
  padding: 0px 10px;
  display: block;
  min-height: ${props => props.theme.navigation.height};
  line-height: ${props => props.theme.navigation.height};
  & > *, & {
    color: ${props => props.highlight ? props.theme.colors.primary : props.theme.colors.text};
    text-decoration: none;
  }
  &:hover > *, &:hover {
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
  }
  & a {
    display: inline-block;
    height: ${props => props.theme.navigation.height};
    line-height: ${props => props.theme.navigation.height};
  }

`;

export default LiLink;
export {NavLi};
