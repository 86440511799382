import React from 'react';
import styled from 'styled-components';



const ContactContainer = styled.div`
  display: container;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  `;

export default ContactContainer;
