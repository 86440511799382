import React from 'react';
import Link from './Link';
import {NavLi} from './Link';
import styled from 'styled-components';
import Measure from 'react-measure';
import {withTheme} from 'styled-components';

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: props.maxWidth,
      open: false,
      openSubMenu: null
    }
  }

  isColapsed = () => {
    return this.state.width < this.props.maxWidth
  };

  changeOpenSubMenu = (i) => () => {
    if (this.state.openSubMenu === i) {
      this.setState({openSubMenu: null});
    }
    else {
      this.setState({openSubMenu: i});
    }
  };

  renderSubMenu = (link,i) => {
    const {
      theme
    } = this.props;
    const {
      openSubMenu
    } = this.state;
    const colapsed = this.isColapsed();
    return (
      <NavLi responsive={colapsed} key={i} onClick={this.changeOpenSubMenu(i)} highlight={openSubMenu===i}>
        {link.text} <img src={require('../../images/Pfeil_unten.png')} style={{height: '10px'}}/>
        <SubMenu responsive={colapsed} visible={openSubMenu===i}>
          <NavUl>
            {link.to.map((subLink,j) => (
              <Link responsive key={i+"."+j} to={subLink.to}>{subLink.text}</Link>
            ))}
          </NavUl>
        </SubMenu>
      </NavLi>
    );
  };

  renderLinks = () => {
    if (!this.isColapsed() || this.state.open) {
      return this.props.links.filter(l => !l.hide).map((link,i) => {
        if (typeof link.to === "string") {
          return (
            <Link responsive={this.isColapsed()} key={i} to={link.to}>
              {link.text}
            </Link>
          );
        } else {
          return this.renderSubMenu(link,i);
        }
      });
    }
  }

  renderMenuButton = () => {
    if (this.isColapsed()) {
      return (
        <NavLi responsive onClick={() => this.setState({open: !this.state.open})}>
          <img src={require('../../images/Menue.png')} style={{height: '20px'}}/>
        </NavLi>
      );
    }
  }

  render() {
    return (
      <Measure bounds onResize={(cr) => this.setState({width: cr.bounds.width})}>
        {({measureRef}) => (
          <div ref={measureRef} style={{width: "100%"}}>
            <NavUl>
              {this.renderMenuButton()}
              {this.renderLinks()}
            </NavUl>
          </div>
        )}
      </Measure>
    );
  }
}

const SubMenu = styled.div`
display: ${props => props.visible ? "block" : "none"};
position: ${props => props.responsive ? "inhert" : "absolute"};
`;

const NavUl = styled.ul`
list-style-type: none;
margin: 0;
padding: 0;
min-height: ${props => props.theme.navigation.height};
background-color: ${props => props.theme.colors.secondary};
`;

export default withTheme(Navigation);
