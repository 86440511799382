import React from 'react';
import styled from 'styled-components';
import Flexbox from './Flexbox';

const Thumbnail = ({src,title,width,onClick}) => (
  <ThumbnailDiv width={width} onClick={onClick}>
    <ThumbnailImg src={src} alt={title} width={width} style={{display: "block"}} />
    {title && <ThumbnailTitle>{title}</ThumbnailTitle>}
  </ThumbnailDiv>
);

const ThumbnailTitle = styled.div`
  padding: 5px;
`;

const ThumbnailDiv = styled.div`
  width: ${props => props.width};
  margin: 10px;
  display: inline-block;
  background-color: ${props => props.theme.colors.secondary};
`;

const ThumbnailContainer = ({width,children}) => (
  <ThumbnailContainerDiv container flexWrap="wrap" justifyContent="center" width="100%">
    {React.Children.map(children,(thumb) => React.cloneElement(thumb,{width}))}
  </ThumbnailContainerDiv>
);

const ThumbnailImg = styled.img`
  display: block;
`;

const ThumbnailContainerDiv = styled(Flexbox)`
  text-align: center;
  width: 100%;
`;
export {ThumbnailContainer};
export default Thumbnail;
