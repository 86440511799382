import React from "react";
import { withTheme } from "styled-components";
import styled from "styled-components";

const Header = withTheme((props) => (
  <div>
    <ResponsiveImg src={props.theme.header.logo} alt="Logo" />
  </div>
));

const ResponsiveImg = styled.img`
  width: 100%;
  height: 100%;
  display: block;
`;

export default Header;
