import React from 'react';
import styled from 'styled-components';


const GridContainer = styled.div`
  display: grid; center;
  @media (max-width: 750px) {
    display: block;
    margin: 10px;
    padding:10px;
  }
  grid-gap: 10px;
  grid-template-columns: 200px 200px auto;
  grid-template-rows: 50px;
  width: 80%;
  margin: auto;
  padding-bottom: 10px;
  padding-top: 20px;
  text-align:left;
  `;

const EventItem = (props) => (
  <GridContainer>
    <div>
    {props.year}
    </div>
    <div>
    {props.days}<br/>
    {props.date}
    </div>
    <div>
    {props.text}
    </div>
  </GridContainer>
);
export default EventItem;
