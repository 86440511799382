import React from 'react';

const Textarea = (props) => (
  <label>
    {props.label}:<br/>
    <textarea {...props} />
    <br/>
  </label>

);

export default Textarea;
