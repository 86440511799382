import React from 'react';

const Textfield = (props) => (
  <label>
    {props.label}:<br/>
    <input {...props} />
    <br/>
  </label>

);

export default Textfield;
