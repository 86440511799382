import React from 'react';
import Gallery from '../../components/Gallery.js';
import TextBlockOben from '../../components/TextBlockOben';

const images = [
  {
    src: require('../../images/TL_Seife_SchafKraeuter_170874.jpg'),
    title: '',
    caption: 'Schafmilchseife mit Kräuterduft - Rosmarin, Melisse, Salbei, Mandarinen- und Lemongrasöl - Frischgewicht: 60g'
  },
  {
    src: require('../../images/TL_Seife_SchafPur_170875.jpg'),
    title: '',
    caption: 'Schafmilchseife pur - ohne Zusatz von Duft und Farbstoffen - Frischgewicht: 60g'
  },
  {
    src: require('../../images/TL_werb_200354.jpg'),
    title: '',
    caption: 'Umfilzte Schafmilchseifen - Pur und Kräuter'
  },
  {
    src: require('../../images/TL_LavendelKlein_170651.jpg'),
    title: '',
    caption: 'Selbstgenähte Lavendelblütensäckchen befüllt mit Lavendel der Provence - Inhalt 10g'
  },
  {
    src: require('../../images/TL_werb_200351.jpg'),
    title: '',
    caption: 'Strandwichtel'
  },
  {
    src: require('../../images/TL_werb_200352.jpg'),
    title: '',
    caption: 'Strickwichtel'
  },
  {
    src: require('../../images/TL_werb_200355.jpg'),
    title: '',
    caption: 'Wichtel'
  }
];


const Accessoires = (props) => {
  console.log(images);
  return (
    <TextBlockOben>
            <h1>Accessoires</h1>
            <p>Schafmilchseife pur und mit Kräutern.<br/><br/>
            Handgenähte Lavendelblütensäckchen zur Beduftung und als altes Hausmittel gegen Motten.<br/><br/>
            Kleine Basteleien für Dekozwecke.<br/><br/>
            Handarbeitshelfer aus Holz - Diz und Nadelmaß<br/><br/> 
            - Für mehr Infos drückt auf das entsprechende Bild. -
            </p>
        <Gallery images={images} />
    </TextBlockOben>
  );
}


export { Accessoires };
