import React from 'react';
import Gallery from '../../components/Gallery.js';
import TextBlockOben from '../../components/TextBlockOben';

const images = [
  {
    src: require('../../images/TL_werb_200353.jpg'),
    title: '',
    caption: 'Diz aus unterschiedlichen Hölzern und Formen zum Ausziehen von Wolle'
  },
  {
    src: require('../../images/TL_diz_uebersicht_180060.jpg'),
    title: '',
    caption: 'Diz aus unterschiedlichen Hölzern und Formen zum Ausziehen von Wolle'
  },
  {
    src: require('../../images/TL_Einzugsh_200409.jpg'),
    title: '',
    caption: 'Einzugshaken Schaf in zwei Größen mit je 9 cm langen Edelstahlhaken ( Drahtstärke ø 2 mm ) '
  },
  {
    src: require('../../images/TL_NadelmAlpaka_200415.jpg'),
    title: '',
    caption: 'Nadelmaß Alpaka'
  },
  {
    src: require('../../images/TL_NadelmLama_200414.jpg'),
    title: '',
    caption: 'Nadelmaß Lama'
  },
  {
    src: require('../../images/TL_NadelmSchaf_200417.jpg'),
    title: '',
    caption: 'Nadelmaß Schaf'
  },
  
];


const Helfer = (props) => {
  console.log(images);
  return (
    <TextBlockOben>
            <h1>Arbeitshelfer</h1>
            <p>Diz, Einzugshaken und Nadelmesser<br/><br/>
            In meinem Atelier handgefertigte kleine Helferlein aus Holz: abstrakt oder in Tiermotiven.<br/><br/>
            Von der Bearbeitung der Rohwolle bis zum Stricken.<br/><br/> 
            - Für mehr Infos drückt auf das entsprechende Bild. -
            </p>
        <Gallery images={images} />
    </TextBlockOben>
  );
}


export { Helfer };
