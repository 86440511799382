import React from "react";
import "./index.css";
import registerServiceWorker from "./registerServiceWorker";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import Router from "./components/Router.js";
import { createRoot } from "react-dom/client";
import styled from "styled-components";

const theme = {
  width: "1000px",
  header: {
    logo: require("./images/TinkasLifeHead_web.png"),
  },
  navigation: {
    height: "40px",
  },
  colors: {
    text: "#333",
    primary: "#0099CC",
    secondary: "#d0e1c2",
  },
};

const GlobalStyle = createGlobalStyle`
  body {
    color: ${theme.colors.text};
    margin: auto;
  }

  .gallery-modal {
    position: absolute;
    top: 15%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -15%);
    max-width: ${(props) => props.theme.width};
    max-height: 80%;
    border: none !important;
  }

  .gallery-modal:focus {
    outline: none;
  }

  .gallery-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

const RootContainer = styled.div`
  max-width: ${(props) => props.theme.width};
  margin: auto;
`;

const Index = (props) => (
  <ThemeProvider theme={theme}>
    <RootContainer>
      <React.Fragment>
        <GlobalStyle />
        <Router />
      </React.Fragment>
    </RootContainer>
  </ThemeProvider>
);

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Index />);

registerServiceWorker();
