import React, { useState, useCallback } from "react";
import styled from "styled-components";
import Textfield from "../components/Textfield.js";
import Textarea from "../components/Textarea.js";
import ContactContainer from "../components/ContactContainer.js";

const Container = styled.div`
  display: grid;
  @media (max-width: 980px) {
    display: block;
    margin: 10px;
    padding: 10px;
  }
  grid-template-columns: 60% 40%;
  grid-template-areas: "header header" "left right";
  .header {
    grid-area: header;
  }
  .left {
    grid-area: left;
    margin-bottom: 20px;
  }
  .right {
    grid-area: right;
    margin-bottom: 20px;
  }
  margin: 0px auto;
`;


class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      subject: "",
      message: "",
      send: 0,
      privacy: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    console.log(value);
    const name = target.name;

    this.setState({ [name]: value });
  }

  handleSubmit = event => {
    if (!this.state.privacy) {
      this.setState({ send: 1 });
      event.preventDefault();
      return;
    };
    fetch("mail.php", {
      method: "POST",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: `firstname=${this.state.firstname}&lastname=${this.state
        .lastname}&email=${this.state.email}&subject=${this.state
          .subject}&message=${this.state.message}`
    })
      .then((response) => {
        let state = { send: response.status };
        if (response.status == 200) {
          state.firstname = "";
          state.lastname = "";
          state.email = "";
          state.subject = "";
          state.message = "";
          state.privacy = false;
        };
        this.setState(state);
      })
      .catch(() => this.setState({ send: 500 }));
    event.preventDefault();
  };

  render() {
    return (
      <ContactContainer>
        <div className="header">
          <center>
            <h1>Kontaktseite</h1>
            <div>
              <p>
                Eure Daten werden nicht an Dritte weitergeleitet.<br /><br />
                Es werden nur die Daten gespeichert, die zu unserer
                Kommunikation erforderlich sind. <br /><br />
                Eure Daten werden verschlüsselt übertragen.
              </p>
            </div>
          </center>
        </div>
        <br /><br />
        <Container>
          <div className="left">
            <form onSubmit={this.handleSubmit}>
              <Textfield
                label="Vorname"
                name="firstname"
                value={this.state.firstname}
                onChange={this.handleInputChange}
              />
              <br />
              <Textfield
                label="Nachname"
                name="lastname"
                value={this.state.lastname}
                onChange={this.handleInputChange}
              />
              <br />
              <Textfield
                label="Email"
                name="email"
                value={this.state.email}
                onChange={this.handleInputChange}
              />
              <br />
              <Textfield
                label="Betreff"
                name="subject"
                value={this.state.subject}
                onChange={this.handleInputChange}
              />
              <br />
              <Textarea
                label="Nachricht"
                name="message"
                value={this.state.message}
                onChange={this.handleInputChange}
              />
              <br />
              <input type="checkbox" name="privacy" checked={this.state.privacy} onChange={this.handleInputChange} />
              Datenschutz
              <br />
              <input type="submit" value="Senden" />
            </form>
            {this.state.send == 200 ? <p>Email erfolgreich versendet.</p> : (this.state.send != 0 ? <p>Ein Fehler ist aufgetreten. Bitte überprüfen Sie Ihre Eingaben.</p> : <p></p>)}
          </div>


          <div className="right">
            <b>Tinka&apos;s Lifestyle Regina Prohn</b><br /><br /><br />
            Regina Prohn<br /><br />
            Neue Str. 21<br /><br />
            25355 Barmstedt<br /><br />
            Kontakt: Regina Prohn<br /><br />
            <div>
              Tel.:<br /><br /><a href="tel:+491725288803">+49 (0) 1725288803</a><br /><br />
            </div>
            <div>
              E-Mail:<br /><br /><a href="mailto:reginaprohn@tinkas-lifestyle.de">reginaprohn@tinkas-lifestyle.de</a><br />
            </div>

          </div>
        </Container>
      </ContactContainer>

    );
  }
}

export { Contact };
