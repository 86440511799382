import React from "react";
import Gallery from "../components/Gallery.js";
import EventItem from "../components/EventItem.js";
import styled from "styled-components";
import Thumbnail from "../components/Thumbnail";
import { ThumbnailContainer } from "../components/Thumbnail";
import { Link } from "react-router-dom";
import TextBlockOben from "../components/TextBlockOben.js";
const ContainerAll = styled.div`
  width: 100%;
  margin-top: 20px;
`;
const Termine = (props) => {
  return (
    <TextBlockOben>
      <h1>Termine</h1>
      <p>
        Wenn Ihr mich und meine Produkte einmal vor Ort kennenlernen möchtet,
        <br />
        dann schaut doch mal vorbei.
      </p>
      <ThumbnailContainer width={320}>
        <Thumbnail title="" src={require("../images/Stand_TL.jpg")} />
      </ThumbnailContainer>
      <div width={280}>
        <h2>April 2024</h2>
        <EventItem
          year="April 2024"
          days="21.04."
          text="Kunsthandwerkermarkt bei Hütters, 24649 Wiemersdorf, Kieler Str. 75"
        />
        <EventItem
          year="April 2024"
          days="27.04. - 28.04."
          text="Kunst- und Antikmarkt im Packhaus Tönning, Am Eiderdamm 18"
        />
        <h2>Juni 2024</h2>
        <EventItem
          year="Juni 2024"
          days="01.06. - 02.06."
          text="Kunst- und Antikmarkt im Packhaus Tönning, Am Eiderdamm 18"
        />
        <h2>Juli 2024</h2>
        <EventItem
          year="Juli 2024"
          days="13.07. - 14.07."
          text="Backensholzer Käsetage, 25885 Oster-Ohrstedt, Damm 8"
        />
        <EventItem
          year="Juli 2024"
          days="20.07. - 21.07."
          text="Kunsthandwerkermarkt im Kurpark, Sandwigstraße, 24960 Glücksburg"
        />
        <h2>August 2024</h2>
        <EventItem
          year="August 2024"
          days="10.8. - 11.08."
          text="Kunsthandwerkermarkt an der Kunsstätte Bossard, Bossardweg 95, 21266 Jesteburg"
        />
        <EventItem
          year="August 2024"
          days="24.8. - 25.08."
          text="Kunst- und Antikmarkt im Packhaus Tönning, Am Eiderdamm 18"
        />
        <h2>September 2024</h2>
        <EventItem
          year="September / Oktober 2024"
          days="28.09. - 30.09."
          text="Herbstmarkt Freilichtmuseum Molfsee, Hamburger Landstraße 97, 24113 Molfsee"
        />
        <h2>Oktober 2024</h2>
        <EventItem
          year="Oktober 2024"
          days="01.10. - 02.10."
          text="Herbstmarkt Freilichtmuseum Molfsee, Hamburger Landstraße 97, 24113 Molfsee"
        />
        <EventItem
          year="Oktober 2024"
          days="26.10. - 27.10."
          text="Glinde Kreativ, Rathaus und Marcellin-Verbe-Haus, Markt 1, 21509 Glinde"
        />
        <h2>November 2024</h2>
        <EventItem
          year="November/Dezember 2024"
          days="29.11. - 01.12."
          text="Weihnachtsmarkt der Kunsthandwerker am 1. Advent, Freilichtmuseum am Kiekeberg"
        />
        <h2>Dezember 2024</h2>
        <EventItem
          year="Dezember 2024"
          days="13.12. - 15.12."
          text="Weihnachtsmarkt der Kunsthandwerker am 3. Advent, Freilichtmuseum am Kiekeberg, Am Kiekeberg 1, 21224 Rosengarten"
        />
      </div>
    </TextBlockOben>
  );
};

export { Termine };
