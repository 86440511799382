import React from "react";
import { Carousel } from "react-responsive-carousel";
import Modal from "react-modal";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";
import { ThumbnailContainer, default as Thumbnail } from "./Thumbnail";
import styled from "styled-components";
import { RiCloseCircleLine } from 'react-icons/ri'

Modal.setAppElement("#root");

const StyledCarousel = styled(Carousel)`
  max-width: 100vw;

  img {
    max-height: 80vh;
    width: auto !important;
    height: auto;
  }

  @media screen and (min-width: ${props => props.theme.width}) {
    img {
      max-width: ${(props) => props.theme.width};
    }
  }

  @media screen and (max-width: ${props => props.theme.width}) {
    img {
      max-width: 100vw;
    }
  }

  .thumb {
    border-color: ${props => props.theme.colors.secondary}
  }

  .thumb:hover, .thumb.selected {
    border-color: ${props => props.theme.colors.primary}
  }
`;

const CloseButton = styled(RiCloseCircleLine)`
  float: right;
  color: ${props => props.theme.colors.secondary};
  font-size: 2em;
  cursor: pointer;
  margin-bottom: 8px;
`;

const Caption = styled.p`
  color: ${props => props.theme.colors.secondary};
`;

const ImageTitle = styled.h1`
  color: ${props => props.theme.colors.secondary};
`;

const Gallery = ({ images }) => {
  const [{ selectedItem, isOpen, openModal }, setState] = React.useState({
    selectedItem: 0,
    isOpen: false,
    openModal: images.map(
      (_, idx) => () => setState({ selectedItem: idx, isOpen: true, openModal })
    ),
  });

  function closeModal() {
    setState({ selectedItem, isOpen: false, openModal });
  }

  return (
    <React.Fragment>
      <ThumbnailContainer width={300}>
        {images.map((img, idx) => (
          <Thumbnail
            src={img.src}
            title={img.title}
            key={idx}
            onClick={openModal[idx]}
          />
        ))}
      </ThumbnailContainer>
      <Modal
        isOpen={isOpen}
        shouldCloseOnEsc={true}
        onRequestClose={closeModal}
        className="gallery-modal"
        overlayClassName="gallery-modal-overlay"
        shouldCloseOnOverlayClick={false}
      >
        <CloseButton onClick={closeModal}><RiCloseCircleLine /></CloseButton>
        <StyledCarousel
          autoPlay={false}
          selectedItem={selectedItem}
          showIndicators={false}
          useKeyboardArrows
          swipeable
          showArrows
          showThumbs={false}
        >
          {images.map((img, idx) => (
            <React.Fragment key={idx}>
              {img.title && <h1>{img.title}</h1>}
              <img src={img.src} title={img.title} />
              <Caption>{img.caption}</Caption>
            </React.Fragment>
          ))}
        </StyledCarousel>
      </Modal>
    </React.Fragment>
  );
};

export default Gallery;
