import React from 'react';
import Thumbnail from "../components/Thumbnail";
import { ThumbnailContainer } from "../components/Thumbnail";
import TextBlockOben from '../components/TextBlockOben';

const images = [
  
];


const Workshop = (props) => {
  console.log(images);
  return (
    <TextBlockOben>
        <h1>Spinnkurse</h1>
        <p>
        Ich biete Euch individuelle Spinnkurse in kleinen Gruppen bis max. 3 Personen an.</p>
        <p>Hierfür stehen anfängertaugliche Räder mit Einzel- und Doppeltritt zur Verfügung.</p>
        <p>-</p>
        <p>Dauer und Inhalt der Kurse werden auf Euer Bedarf / Interesse abgestimmt.</p>
        <br/>
        <ThumbnailContainer width={750}>
            <Thumbnail title="" src={require('../images/TL_werb_200336.jpg')}/>
          </ThumbnailContainer>
          <p>v. lks.: Kromski Minstrel / Küstenspinner / Moswolt</p>
    </TextBlockOben>
  );
}


export { Workshop };
