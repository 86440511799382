import React from 'react';
import styled from 'styled-components';


const TextContainer = styled.div`
  display: container;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  max-width: 980px;
  `;

export default TextContainer;
