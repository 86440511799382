import React from 'react';
import styled from 'styled-components';
import Thumbnail from '../components/Thumbnail';
import {ThumbnailContainer} from '../components/Thumbnail';
import {Link} from "react-router-dom";
import TextBlockOben from '../components/TextBlockOben';

const Home = (props) => (
  <TextBlockOben>
      <h1>Herzlich willkommen!</h1>
          <p>Ich liebe Wolle und deren vielseitige Verwendungsmöglichkeiten.
          </p>
          <p>Ihr findet hier:
          </p>
          <p>
              - Handgefilzte <Link to="/galerie/Sitzkissen">Sitzkissen</Link><br/><br/>
              - handgesponnene und selbstgefärbte <Link to="/galerie/Wolle">Wolle</Link><br/><br/>
              - kleine <Link to="/galerie/Helfer">Helferlein aus Holz</Link> für die Wollverarbeitung<br/><br/> 
              - <Link to="/galerie/Schals">Schals</Link> gestrickt<br/><br/>
              - <Link to="/galerie/Accessoires">Accessoires</Link> von, für und mit Wolle.<br/><br/><br/>
          </p>
          <p>  Ich wünsche Euch viel Spaß beim Stöbern!
          </p>

    <ThumbnailContainer width={280}>
      <Thumbnail title="Schafwolle, Angorawolle, Babykamel" src={require('../images/TL_Wolle_170314.jpg')}/>
      <Thumbnail title="Helferlein vom Spinnen bis Stricken" src={require('../images/TL_DizEinzNadelmUebers_200408.jpg')}/>
      <Thumbnail title="Sitzkissen" src={require('../images/TL_Sitzkissen_170869.jpg')}/>
      <Thumbnail title="Schals - genäht, gestrickt" src={require('../images/TL_werb_200348.jpg')}/>
      <Thumbnail title="Accessoires - von, für und mit Wolle" src={require('../images/TL_werb_200338e.jpg')}/>
    </ThumbnailContainer>
  </TextBlockOben>
);

export { Home };
