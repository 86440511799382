import React from 'react';
import Gallery from '../../components/Gallery.js';
import TextBlockOben from '../../components/TextBlockOben';

const images = [
  {
    src: require('../../images/TL_werb_200348.jpg'),
    title: '',
    caption: 'Loop aus handgesp. und -gefärbter 100% Angorawolle'
  },
  {
    src: require('../../images/TL_werb_200349.jpg'),
    title: '',
    caption: 'Loop aus handgesponnenem Garn, 26% Merino, 26% Seide, 48% Baumwolle'
  },
  {
    src: require('../../images/TL_werb_200350.jpg'),
    title: '',
    caption: 'Loop aus handgesponnenem Garn, 26% Merino, 26% Seide, 48% Baumwolle'
  },
  {
    src: require('../../images/TL_SchalSchafw100Coilgruen_180059.jpg'),
    title: '',
    caption: 'Loop aus handgesponnener 100% Merinowolle / Coilgarn'
  },
];


const Schals = (props) => {
  console.log(images);
  return (
    <TextBlockOben>
            <h1>Schals</h1>
            <p>Schals aus unterschiedlichen, handgesponnen Garnen</p>
        <Gallery images={images} />
    </TextBlockOben>
  );
}


export { Schals };
