import React from 'react';
import TextBlockOben from '../components/TextBlockOben';

  const Impressum = (props) => (
<TextBlockOben>
<h1>Impressum</h1>

<p>Tinka's Lifestyle Regina Prohn<br />
Regina Prohn<br />
Neue Str. 21<br />
25355 Barmstedt</p>

<h2>Kontakt</h2>
<p>Telefon: 01725288803<br />
E-Mail: reginaprohn@tinkas-lifestyle.de</p><br />
Kleinunternehmen:<br />
Aufgrund §19UStG wird die Umsatzsteuer nicht erhoben.<br />
Finanzamt Elmshorn: StNr.: 13/158/02553<br />

<h2>Umsatzsteuer-ID</h2>
<p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
DE 310 518 116</p>

<h2>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
<p><strong>Name und Sitz des Versicherers:</strong><br />
N&uuml;rnberger Versicherung<br />
Ostendstra&szlig;e 100<br />
90334 N&uuml;rnberg</p>
<p><strong>Geltungsraum der Versicherung:</strong><br />Europa</p>

<h2>EU-Streitschlichtung</h2>
<p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

<h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
<p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

<p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>


</TextBlockOben>
  );

  export { Impressum };
