import {
  Home,
  Contact,
  Accessoires,
  Schals,
  Sitzkissen,
  Wolle,
  Helfer,
  Workshop,
  Termine,
  Impressum,
  Datenschutz,
  News,
} from "../routes";

const routes = [
  { text: "Home", to: "/", component: Home },
  {
    text: "Galerie",
    to: [
      {
        text: "Sitzkissen",
        to: "/galerie/sitzkissen",
        component: Sitzkissen
      },
      { text: "Wolle", to: "/galerie/wolle", component: Wolle },
      { text: "Helfer", to: "/galerie/helfer", component: Helfer },
      { text: "Schals", to: "/galerie/schals", component: Schals },
      {
        text: "Accessoires",
        to: "/galerie/accessoires",
        component: Accessoires
      }
    ]
  },
  
  { text: "Termine", to: "/termine", component: Termine },
  { text: "News", to: "/news", component: News },
  { text: "Workshop", to: "/workshop", component: Workshop },
  { text: "Kontakt", to: "/contact", component: Contact },
  { text: "Impressum", to: "/impressum", component: Impressum, hide: true },
  {
    text: "Datenschutz",
    to: "/datenschutz",
    component: Datenschutz,
    hide: true
  }
];

export default routes;
