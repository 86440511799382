import React from "react";
import NewsItem from "../components/NewsItem";
import TextBlockOben from "../components/TextBlockOben.js";

const News = () => (
  <TextBlockOben>
    <h1>News</h1>
    <p>
      Wenn Ihr mich und meine Produkte einmal vor Ort kennenlernen möchtet,
      <br />
      dann schaut doch mal in die Termine oder vereinbart einen Besuchstermin.
    </p>
    <NewsItem
      title="Kleine Helferlein"
      image={require("../images/TL_DizEinzNadelmUebers_200408.jpg")}
      date="05.05.2020"
    >
      Etwas Edles aus Holz!<br/>
      Kleine Helferlein als Einzelstücke handgefertigt in meinem Atelier.<br/> 
      Sie helfen Euch von der Rohwolle bis zum Stricken.<br/>
      Diz für die Rohwolle, Einzugshaken fürs Spinnrad, Nadelmaß für Eure Stricknadeln.<br/>
      Motive: Schaf, Alpaka, Lama 
      
    </NewsItem>
    <NewsItem
      title="Rohwolle"
      image={require("../images/TL_werb_200337.jpg")}
      date="29.03.2020"
    >
      Ich habe wieder Rohwolle abzugeben.<br/>
      Vorsortierte Teilvliese vom Rücken zum Spinnen und Basteln.<br/>
      Sowie Rohwollreste für den Garten.
      
    </NewsItem>
    <NewsItem
      title="Corona - Krise"
      image={require("../images/TL_werb_200356e.jpg")}
      date="29.03.2020"
    >
      Aufgrund der Corona-Krise werden einige der genannten Veranstaltungen ausfallen müssen.<br/>
      Es ist bis jetzt nicht abzusehen, wann wieder Veranstaltungen stattfinden werden.<br/>
      Bitte orientiert Euch deshalb auf den Websites der Veranstalter.<br/><br/>
      -------------------<br/><br/><br/>
      <b>Online bin ich weiterhin für Euch erreichbar.</b>
      
    </NewsItem>
    <NewsItem
      title="Pflanzengefärbte Garne"
      image={require("../images/TL_werb_200343.jpg")}
      date="15.08.2019"
    >
      In diesem Jahr habe ich angefangen meine Garne auch mit Pflanzen zu färben.<br/><br/> 
      Hier seht Ihr die ersten Ergebnisse.
      
    </NewsItem>
    <NewsItem
      title="Unsere Wolllieferanten"
      image={require("../images/TL_Schafe_190158.jpg")}
      date="21.07.2019"
    >
      Ein Besuch bei unseren Wollieferanten im Sommerkleid auf ca. 1000 m im Hochschwarzwald.<br/><br/>
      Es ist eine bunte Mischung aus Coburger Fuchs, Rauhwolligem Pommerschaf und Juraschaf.
      
    </NewsItem>
  </TextBlockOben>
  
);

export { News };
