import React from 'react';
import Gallery from '../../components/Gallery.js';
import TextBlockOben from '../../components/TextBlockOben';

const images = [
  {
    src: require('../../images/TL_Schafwolle100Coilgruen_180058.jpg'),
    title: '',
    caption: 'Coilgarn, Schafwolle (Merino), 100%, LL: ca. 120m / 100g'
  },
  {
    src: require('../../images/TL_Schafwolle100Coilviolett_180057.jpg'),
    title: '',
    caption: 'Coilgarn, Schafwolle (Merino), 100%, LL: ca. 135m / 100g'
  },
  {
    src: require('../../images/TL_werb_200342.jpg'),
    title: '',
    caption: 'Strick- u. Häkelgarn, 26% Merino / 26% Seide / 48% Baumwolle, handgesp., LL: ca. 180m / 50g'
  },
  {
    src: require('../../images/TL_werb_200343.jpg'),
    title: '',
    caption: 'Merino - Seide, 50% / 50%, handgesp. und -gefärbt, pflanzengefärbt: Schilfblüte LL: ca. 260m / 50g'
  },
  {
    src: require('../../images/TL_werb_200344.jpg'),
    title: '',
    caption: 'Merino - Seide, 50% / 50%, handgesp. und -gefärbt, LL: ca. 190m / 50g'
  },
  {
    src: require('../../images/TL_werb_200345.jpg'),
    title: '',
    caption: 'Merino - Seide, 50% / 50%, handgesp. und -gefärbt, LL: ca. 300m / 50g'
  },
  {
    src: require('../../images/TL_MerinoSeide5050pastell_180046.jpg'),
    title: '',
    caption: 'Merino - Seide, 50% / 50%, handgesp. und -gefärbt, LL: ca. 195m / 50g'
  },
  {
    src: require('../../images/TL_werb_200341.jpg'),
    title: '',
    caption: 'Angora, 100%, handgesponnen, LL: ca. 125m / 25g'
  },
  {
    src: require('../../images/TL_werb_200339.jpg'),
    title: '',
    caption: 'Angora, 100%, handgesponnen und -gefärbt, LL: ca. 120m / 25g'
  },
  {
    src: require('../../images/TL_werb_200340.jpg'),
    title: '',
    caption: 'Angora, 100%, handgesponnen und -gefärbt, LL: ca. 130m / 25g'
  },
  {
    src: require('../../images/TL_Angora100rotblau_180045.jpg'),
    title: '',
    caption: 'Angora, 100%, handgesponnen und -gefärbt, LL: ca. 125m / 25g'
  },
  {
    src: require('../../images/TL_Angora100gelbgruen_180043.jpg'),
    title: '',
    caption: 'Angora, 100%, handgesponnen und -gefärbt, LL: ca. 140m / 25g'
  },
  {
    src: require('../../images/TL_Angora100gelbrotweiss_180044.jpg'),
    title: '',
    caption: 'Angora, 100%, handgesponnen und -gefärbt, LL: ca. 140m / 25g'
  },
  {
    src: require('../../images/TL_Babyk100rot_180040.jpg'),
    title: '',
    caption: 'Babykamelwolle, 100%, handgesponnen und -gefärbt, LL: ca. 115m / 25g'
  },
  {
    src: require('../../images/TL_Babyk100gruenblau_180039.jpg'),
    title: '',
    caption: 'Babykamelwolle, 100%, handgesponnen und -gefärbt, LL: ca. 130m / 25g'
  },
  {
    src: require('../../images/TL_Schafwolle100grblau_180053.jpg'),
    title: '',
    caption: 'Schafwolle (Gotlandlammw.), 100%,  mit handgefärbt Merino-Beilaufgarn, LL: ca. 70m / 50g'
  },
  {
    src: require('../../images/TL_werb_200346.jpg'),
    title: '',
    caption: 'Spiralgarn, 100% Merino, handgesp., LL: ca. 70m / 50g'
  },
  {
    src: require('../../images/TL_werb_200347.jpg'),
    title: '',
    caption: 'Spiralgarn, 100% Merino, handgesp., LL: ca. 160m / 100g'
  },
];


const Wolle = (props) => {
  console.log(images);
  return (
    <TextBlockOben>
            <h1>Wolle</h1>
            <p>Aus verschiedenen Rohwollfasern handgesponnene Garne.</p>
            <p>Die handgefärbte Wolle unterscheidet sich von Färbung zu Färbung,<br/>
            daher handelt es sich bei den abgebildeten Garnen um Farbbeispiele.</p>
            <p>Bei Interesse kontaktiert mich bitte,<br/>
            ich schicke euch dann Infos und Fotos der zur Zeit verfügbaren Garne zu.</p>
            <p>- Für mehr Infos drückt auf das entsprechende Bild. -
            </p>
        <Gallery images={images} />
    </TextBlockOben>
  );
}


export { Wolle };
